/* eslint-disable react/jsx-no-target-blank */
import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import React from "react";
import axios from "axios";

function App(props) {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home name={props.name} />} />
        <Route path="invest" element={<Invest />} />
      </Routes>
    </BrowserRouter>
  );
}

function Home(props) {
  return (
    <div className="App">
      <header className="App-header">
        <h1>{props.name}</h1>
        <p>The Home of UNBLND</p>
        <a className="App-link" href="https://unblnd.com/app" target="_blank">
          Start to Play
        </a>
        <br />
        <br />
        <br />
        <small style={{ fontSize: "0.8rem" }}>
          Kind Greetings,
          <br />
          Adriaan De Bolle & Dennis Van der Putten
        </small>
      </header>
    </div>
  );
}

function Invest() {
  const [showSubmitted, setShowSubmitted] = React.useState(false);
  const [name, setName] = React.useState("");
  const [isUnblndKnown, setIsUnblndKnown] = React.useState(null);
  const [email, setEmail] = React.useState("");
  const [give, setGive] = React.useState({});
  const [perks, setPerks] = React.useState({});
  const handleSubmit = async (event) => {
    event.preventDefault();
    setShowSubmitted(false);
    try {
      await axios.post(
        "https://d302djbvh7.execute-api.eu-west-1.amazonaws.com/default/invest",
        { name, email, give, perks, isUnblndKnown }
      );
      setShowSubmitted(true);
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <>
      <div className="App" id="intro">
        <header className="App-header">
          <p>
            Do you know{" "}
            <a href="https://unblnd.com" target="_blank">
              UNBLND
            </a>
            ?
          </p>
          <div style={{ display: "inline-block" }}>
            <a
              className="button"
              href="#why"
              onClick={() => setIsUnblndKnown(true)}
            >
              Yes
            </a>
            <a
              className="button"
              href="#what"
              onClick={() => setIsUnblndKnown(false)}
            >
              No
            </a>
          </div>
        </header>
      </div>
      <div className="App" id="what">
        <header className="App-header">
          <h1>What is UNBLND?</h1>
          <p style={{ padding: "0 15%" }}>
            <a href="https://unblnd.com" target="_blank">
              UNBLND
            </a>{" "}
            is a social network -available via web and mobile app- that connects
            anonymous people into groups, using a matchmaking algorithm based on
            interests, preferred activities, location and other parameters.{" "}
            <br /> <br />
            Ubigreat bv is the mother ship 🛸 (owner and developer).
          </p>
          <a className="button" href="#why">
            Okay!
          </a>
        </header>
      </div>
      <div className="App" id="why">
        <header className="App-header">
          <h1>UNBLND's mission 🌍</h1>
          <p>
            The universe's most inclusive community to discover and make you
            feel good in any environment.
            <br />
            <br />
          </p>
          <a className="button" href="#form">
            Okay!
          </a>
          <p style={{ padding: "0 15%" }}>
            <small>
              PS: read more about our goal:
              <br />
              <a
                target="_blank"
                href="https://unblnd.com/blog/how-we-aim-to-be-the-universes-inclusive-community-discover-make-you-feel-good"
              >
                How we aim to be the universe's most inclusive community to
                discover and make you feel good in any environment.
              </a>
            </small>
          </p>
        </header>
      </div>
      <div className="App" id="form">
        <header className="App-header">
          <h1>How can you contribute?</h1>
          <small>Together we will make the dream work.</small>
          <p>
            <small>
              We are setting our financing strategy and preparing a feasible
              "finance mix".
              <br /> Thank you for completing this form to stay tuned.
            </small>
          </p>
          <form onSubmit={handleSubmit}>
            <div>
              <label htmlFor="name">Name:</label>
              <input
                type="text"
                id="name"
                name="name"
                maxLength="128"
                onChange={(e) => setName(e.target.value)}
              />
            </div>

            <div>
              <label htmlFor="email">Email:</label>
              <input
                type="text"
                id="email"
                name="email"
                maxLength="254"
                required
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>

            <p>I can offer:</p>

            <div>
              <input
                type="checkbox"
                id="money"
                name="give[]"
                onChange={(e) => setGive({ ...give, money: e.target.checked })}
              />
              <label htmlFor="money">Financial support 💶</label>
            </div>

            <div>
              <input
                type="checkbox"
                id="knowledge"
                name="give[knowledge]"
                onChange={(e) =>
                  setGive({ ...give, knowledge: e.target.checked })
                }
              />
              <label htmlFor="knowledge">Knowledge 🧠 </label>
            </div>

            <div>
              <input
                type="checkbox"
                id="money"
                name="give[money]"
                onChange={(e) => setGive({ ...give, money: e.target.checked })}
              />
              <label htmlFor="money">(Social) media fuzz 🗣️ </label>
            </div>

            <div>
              <input
                type="checkbox"
                id="love"
                name="give[]"
                onChange={(e) => setGive({ ...give, love: e.target.checked })}
              />
              <label htmlFor="love">Love 💕 </label>
            </div>

            <div>
              <input
                type="checkbox"
                id="nothing_"
                name="give[nothing]"
                onChange={(e) =>
                  setGive({ ...give, nothing: e.target.checked })
                }
              />
              <label htmlFor="nothing_">Nothing 🕳 </label>
            </div>

            <p>I want in return:</p>

            <div>
              <input
                type="checkbox"
                id="shares"
                name="perks[shares]"
                onChange={(e) =>
                  setPerks({ ...perks, shares: e.target.checked })
                }
              />
              <label htmlFor="shares">Shares 💯</label>
            </div>

            <div>
              <input
                type="checkbox"
                id="rewards"
                name="perks[rewards]"
                onChange={(e) =>
                  setPerks({ ...perks, rewards: e.target.checked })
                }
              />
              <label htmlFor="rewards">Rewards 🎁</label>
            </div>

            <div>
              <input
                type="checkbox"
                id="oxytocin"
                name="perks[oxytocin]"
                onChange={(e) =>
                  setPerks({ ...perks, oxytocin: e.target.checked })
                }
              />
              <label htmlFor="oxytocin">Oxytocin 🤗</label>
            </div>

            <div>
              <input
                type="checkbox"
                id="nothing"
                name="perks[nothing]"
                onChange={(e) =>
                  setPerks({ ...perks, nothing: e.target.checked })
                }
              />
              <label htmlFor="nothing">Nothing 🕳</label>
            </div>
            {showSubmitted && (
              <div>
                <p style={{ color: "green" }}>
                  Thank you! Your contact details are submitted.
                </p>
              </div>
            )}
            <div>
              <p />
              <button className="button" type="submit">
                Send
              </button>
            </div>
            <small>Keep you posted. 🙏 </small>
          </form>
          <p />
        </header>
      </div>
    </>
  );
}

export default App;
